import "core-js/modules/es.array.push.js";
import { feeDesc, mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

import detail from "./detail";
export default {
  name: "feeStandardRuleAudit",
  mixins: [mixins],
  components: {
    detail
  },
  data() {
    return {
      currentId: '',
      detailDrawer: false,
      columns: [{
        type: 'expand',
        typeSlot: 'itemExpand'
      }, {
        label: '隔日计费类型',
        width: '120px',
        prop: 'nextDayType'
      }, {
        label: '区域',
        prop: 'areaName'
      }, {
        label: '周末是否计费',
        width: '120px',
        customRender: props => {
          return props.row.weekend ? '计费' : '不计费';
        }
      }, {
        label: '法定假日是否计费',
        width: '150px',
        customRender: props => {
          return props.row.holiday ? '计费' : '不计费';
        }
      }, {
        label: '封顶金额',
        width: '120px',
        customRender: props => {
          if (props.row.maxFee > 0) {
            return props.row.maxFee;
          } else {
            return '不封顶';
          }
        }
      }, {
        label: '超运营停放调度费用',
        prop: 'outFenceFee'
      }, {
        label: '站外停放调度费用',
        prop: 'nonParkFee'
      }, {
        label: '生效日期',
        width: '120px',
        prop: 'beginDay'
      }, {
        label: '费率描述',
        prop: 'feeDesc'
      }, {
        label: '审核状态',
        width: '120px',
        prop: 'status'
      }],
      topButtons: [{
        name: '新增',
        code: 'feeStandardRuleAudit_add',
        type: 'primary',
        click: this.add,
        permissions: ['feeStandardRuleAudit_add']
      }, {
        name: '导出',
        code: 'feeStandardRule_export',
        type: 'primary',
        click: this.inputInfo,
        permissions: ['feeStandardRule_export']
      }],
      linkButtons: [{
        name: '修改',
        code: 'feeStandardRuleAudit_edit',
        click: this.edit,
        permissions: ['feeStandardRuleAudit_edit'],
        show: row => {
          return row.status === '待审核';
        }
      }, {
        name: '删除',
        code: 'feeStandardRuleAudit_del',
        click: this.del,
        permissions: ['feeStandardRuleAudit_del'],
        show: row => {
          return row.status === '待审核';
        }
      }, {
        name: '审核',
        code: 'feeStandardRuleAudit_check',
        click: this.check,
        type: 'danger',
        permissions: ['feeStandardRuleAudit_check'],
        show: row => {
          return row.status === '待审核';
        }
      }, {
        name: '复制',
        code: 'feeStandardRuleAudit_copy',
        click: this.copy,
        type: 'danger',
        permissions: ['feeStandardRuleAudit_copy'],
        show: row => {
          return row.status === '审核通过';
        }
      }],
      searchItems: [{
        prop: 'status',
        label: '审核状态',
        type: 'select',
        items: ['待审核', '审核通过', '审核未通过']
      }, {
        type: 'cascader',
        prop: 'areaId',
        label: '区域',
        props: {
          checkStrictly: true,
          emitPath: false,
          value: 'id',
          label: 'name',
          lazy: true,
          lazyLoad: (node, resolve) => {
            const {
              level
            } = node;
            if (level === 0) {
              this.$api.uac.area.list({}).then(res => {
                if (res.code === 200) {
                  resolve(res.data);
                } else {
                  resolve([]);
                }
              });
            } else {
              resolve([]);
            }
          }
        }
      }]
    };
  },
  methods: {
    inputInfo() {
      const loading = ElLoading.service();
      const search = this.$refs.tablePage.searchFormModel;
      this.$api.business.feeStandardRuleAudit.exportInfo(search).then(res => {
        this.blobFile(res, '收费规则.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {});
      }).finally(() => {
        loading.close();
      });
    },
    add() {
      this.$router.push('/feeStandardRuleAuditAdd');
    },
    edit(row) {
      this.$router.push({
        path: '/feeStandardRuleAuditAdd',
        query: {
          id: row.id
        }
      });
    },
    check(row) {
      this.currentId = row.id;
      this.detailDrawer = true;
    },
    doCheck(pass) {
      ElMessageBox.confirm(`确认要${pass ? '审核通过' : '拒绝'}该费率吗?`, '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.feeStandardRuleAudit.check(this.currentId, pass).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.detailDrawer = false;
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    },
    copy(row) {
      ElMessageBox.confirm('确认要复制该费率吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.feeStandardRuleAudit.copy({
          feeStandardRuleAuditId: row.id
        }).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    },
    del(row) {
      ElMessageBox.confirm('确认要删除该费率吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.feeStandardRuleAudit.del(row.id).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    },
    showFeeRuleDesc(feeRule) {
      return feeDesc(feeRule);
    },
    loadItems(item) {
      if (!item.id) {
        return;
      }
      this.$api.business.feeStandardRuleAudit.items(item.id).then(res => {
        if (res.code === 200) {
          item.items = res.data;
        } else {
          ElMessage.error(res.message);
          item.items = [];
        }
      });
      return '加载中';
    }
  },
  beforeMount() {}
};